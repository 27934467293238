/* NAVIGATION */
nav {
  color: #f0f0f0;
  height: 75px;
  overflow: visible !important;
  position: absolute;
  width: 100%;
  display: block;
  top: 0;
  z-index: 200;
}
nav .navbar {
  height: 75px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 5px 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
nav .navbar .logo {
  height: 60px;
  padding: 0;
  margin: 0;
}
nav .navbar .navigation {
  display: block;
}
nav .navbar .navigation a {
  text-decoration: none;
}
nav .navbar .navigation .NavLink {
  color: #f0f0f0;
  text-transform: none;
  font-weight: 200;
  font-size: 14px;
}
nav .responsive-navbar #nav-toggle {
  position: absolute;
  top: 15px;
  right: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
nav .responsive-navbar #nav-toggle img {
  width: 25px;
}
nav .responsive-navbar .nav-links {
  background-color: #f0f0f0;
  z-index: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100vw;
  padding-top: 50px;
  padding-bottom: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  justify-items: right;
  -ms-grid-column-align: right;
      justify-self: right;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-left: 100vw;
  margin-top: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
nav .responsive-navbar .nav-links li {
  margin-top: 10px;
  margin-bottom: 10px;
}
nav .responsive-navbar .nav-links li a {
  font-size: 1rem;
  font-weight: 400;
  color: black;
  text-decoration: none !important;
}
nav .responsive-navbar .nav-links li a:hover, nav .responsive-navbar .nav-links li a:active {
  text-decoration: underline;
}

.nav-button {
  padding: 16px 1px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: capitalize;
  color: white;
  font-size: 0.8rem;
  font-weight: 300;
  letter-spacing: 0.4px;
}

.nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  list-style-type: none;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0;
}
.nav-links li {
  font-size: 0.8rem;
  letter-spacing: 1.2px;
  font-weight: 400;
  padding: 1px 25px;
}

@media screen and (min-width: 769px) {
  nav .responsive-navbar {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  nav .navbar .navigation {
    display: none;
  }
}
nav .responsive-navbar #responsive-nav-links {
  display: block;
  background-color: #0a0a0a;
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: -40;
  padding-top: 105px;
} 
nav .responsive-navbar #responsive-nav-links .nav-item,   nav .responsive-navbar #responsive-nav-links .nav-item * {
  color: #f0f0f0;
  margin-top: 20px;
  margin-bottom: 20px;

}