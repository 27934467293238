@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Cormorant:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");


/* HEADER */


h1 {
  font-weight: 200 !important;
  font-size: clamp(52px, 6vw, 80px) !important;
  font-family: "Comfortaa", sans-serif;
}

h2 {
  font-weight: 200 !important;
  font-size: clamp(40px, 4.5vw, 76px) !important;
  font-family: "Cormorant", serif;
}

h3 {
  font-weight: 200 !important;
  font-size: clamp(22px, 4vw, 32px) !important;
  font-family: "Comfortaa", sans-serif;
}

h4 {
  font-weight: 200 !important;
  font-size: clamp(22px, 3vw, 28px) !important;
  font-family: "Cormorant", serif;
}

header .home-overlay {
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  z-index: 10;
}

header {
  margin-top: 75px;
}

.home-header {
  margin: 0;
  padding: 0;
  background-image: url(../images/hero-cover.jpeg);
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: absolute;
  top: 0;
  width: 100%;
}
.home-header .hero-text {
  max-width: 1200px;
  padding: 20px;
  z-index: 20;
  position: absolute;
  bottom: 10vh;
}
.home-header .hero-text h1 {
  font-weight: 200;
  font-size: clamp(52px, 6vw, 80px);
  font-family: "Comfortaa", sans-serif;
  margin-bottom: 30px;
  max-width: 700px;
}
.home-header .hero-text h2 {
  font-weight: 200;
  font-size: clamp(40px, 4.5vw, 76px);
  font-family: "Cormorant", serif;
}
.home-header h1, .home-header h2 {
  color: #f0f0f0;
}
.home-header .main-title {
  font-size: 2.5rem;
  font-weight: 600;
}
.home-header .main-subtitle {
  font-size: 1.3rem;
  font-weight: 300;
  margin-bottom: 30px;
  color: #CAA024;
}
.home-header .purple-button {
  margin: 8px 0;
}

@media screen and (min-width: 992px) {
  .home-header {
    background-position: -50%, -30%;
  }
  .home-header .hero-text {
    margin-left: 40px;
    padding-top: 120px;
  }
}
/* MAIN */
.home-info {
  max-width: 1100px;
  display: block;
  margin-top: calc(100vh + 105px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 105px;
  padding: 15px;
}
.home-info .row {
  margin: 40px 0;
}
.home-info .col {
  position: relative;
}
.home-info h2 {
  max-width: 700px;
}
.home-info img {
  height: 580px;
  width: 360px;
  object-fit: cover;
  object-position: center;
  margin: 0 auto;
}

@media screen and (min-width: 993px) {
  .home-info img {
    position: absolute;
    right: 5%;
    top: -10%;
  }
}
@media screen and (max-width: 992px) {
  .home-info {
    margin-top: calc(100vh + 40px);
    margin-bottom: 40px;
  }
}
.unique-wrapper {
  background-color: #f8f4e7;
  padding: 10px 0;
}

.unique {
  max-width: 1100px;
  display: block;
  margin: 105px auto;
  padding: 15px;
}
@media screen and (max-width: 992px) {
  .unique {
    margin: 40px auto;
  }
}
.unique h2 {
  text-align: center;
  width: fit-content;
  margin: auto;
  color: #866502;
  margin-bottom: 40px;
}
.unique h3 {
  margin-bottom: 15px;
}
.unique .col {
  margin: 40px 0;
}

@media screen and (max-width: 992px) {
  .col {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .row {
    margin: 0;
  }
}
@media screen and (min-width: 993px) {
  .unique h2 {
    margin-bottom: 75px;
  }
  .unique .col p {
    max-width: 460px;
  }
}
.services {
  max-width: 1100px;
  width: 80vw;
  display: block;
  margin: 105px auto;
  padding: 10px;
  margin-top: 105px !important;
}
@media screen and (max-width: 992px) {
  .services {
    margin: 40px auto;
  }
}
.services h2 {
  margin-bottom: 105px !important;
  font-size: clamp(40px, 4.5vw, 56px);
  color: #11143D;
}
.services h4 {
  text-align: center;
}
/*.services .service-card {
  display: block;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.services .service-card img {
  width: 240px;
  height: 240px;
  object-fit: cover;
  object-position: center;
  margin: 10px auto;
}*/
.services .gold-button {
  margin: 75px auto;
}
.services-content .Link {
  width: fit-content;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 992px) {
  .services h2 {
    margin-bottom: 30px;
  }
  .services .gold-button {
    margin: 40px auto;
  }
}
.projects {
  margin-top: 105px;
  margin-bottom: 0;
  padding-top: 75px;
  padding-bottom: 0;
  background-color: #212121;
}
.projects .col, .projects .row {
  margin: 0;
  padding: 0;
}
.projects .col {
  display: flex;
  justify-content: center;
  align-items: center;
}
.projects h2 {
  margin: 0 auto;
  width: fit-content;
  color: #E7E0CC;
  margin-bottom: 75px;
}
.projects h4 {
  margin-bottom: 30px;
}
.projects img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center;
}
.projects #header-winchmore {
  color: #F3D476;
}
.projects #header-hadley {
  color: #9497BD;
}
.projects p {
  color: #bbbbbb;
  font-family: 15px;
}
.projects .content-wrapper {
  max-width: 500px;
  padding: 15px;
}

@media screen and (min-width: 768px) {
  .projects img {
    width: 50vw;
    height: clamp(600px, 50vw, 680px);
  }
}
.testimonials {
  max-width: 1100px;
  display: block;
  margin: 105px auto;
  padding: 15px;
}
@media screen and (max-width: 992px) {
  .testimonials {
    margin: 40px auto;
  }
}
.testimonials h2 {
  margin: 75px auto;
  width: fit-content;
}
.testimonials .carousel-inner {
  height: 360px;
}
.testimonials .blockquote-footer {
  margin-left: auto;
  margin-right: auto;
  max-width: 760px;
  color: #866502;
  font-family: "Cormorant", serif;
  text-align: right;
  font-size: 24px;
}
.testimonials .review-text {
  max-width: 1100px;
  display: block;
  margin: 20px auto;
  padding: 10px;
}
.testimonials .review-text p {
  text-align: center;
  max-width: 760px;
  margin: 0 auto;
  display: block;
}

@media screen and (max-width: 992px) {
  .testimonials {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .testimonials h2 {
    margin: 30px auto;
  }
  .testimonials .carousel-inner {
    height: 280px;
  }
  .testimonials .review-text {
    padding: 5px;
  }
  .testimonials .carousel-indicators {
    display: none;
  }
  .testimonials .carousel-control-prev, .testimonials .carousel-control-next {
    display: none;
  }
}
/*
.testimonials {
    background-color: #0E0E0E;
    margin: 105px auto;
    color: #fafafa;
    .carousel-inner {
        height: 360px;
    }
    .blockquote-footer {
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        color: $light-gold;
        font-family: 'Cormorant', serif;
        text-align: right;
        font-size: 24px;
    }
    .review-text {
        max-width: 1100px;
        display: block;
        margin: 20px auto;
        padding: 10px;
        p {
            color: #F0F0F0;
            text-align: center;
            max-width: 600px;
            margin: 0 auto;
            display: block;
        }
    }
}

@media screen and (max-width: 992px) {

.testimonials {

    margin-top: 40px;
    margin-bottom: 20px;

    .display-2 {
        font-size: 1.6rem; 
        margin-bottom: 10px;
    }
    .review-text {
        padding: 5px;
        p {
            font-size: .9rem;
            line-height: 33px;
        }
    }

    .carousel-indicators {
        display: none;
    }
    .carousel-control-prev, .carousel-control-next {
        display: none;
    }
}
}
*/
.bottom-cta {
  max-width: 1100px;
  display: block;
  margin: 105px auto;
  padding: 15px;
  padding-top: 105px;
  padding-bottom: 175px;
  z-index: 20px;
  margin-bottom: 0 !important;
}
@media screen and (max-width: 992px) {
  .bottom-cta {
    margin: 40px auto;
  }
}
.bottom-cta h2 {
  margin-bottom: 75px;
  color: #f8f4e7;
}
.bottom-cta .gold-button {
  margin-bottom: 30px;
}
.bottom-cta p {
  max-width: 600px;
  color: #F0F0F0;
}

.bottom-cta-wrapper {
  width: 100%;
  height: fit-content;
  background-image: url("../images/bottom-cta-cover.jpeg");
  background-size: cover;
  background-position: center;
}

.bottom-cta-overlay {
  height: fit-content;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}


.services-content .buttonLink {
  margin: 40px auto;
}
.buttonLink {
  display: block;
  width: fit-content;
  position: relative;
  overflow: hidden;
}
.services-content .buttonLink * {
  margin: 0;
}