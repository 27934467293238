.service-card-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  text-align: center;
}
.service-card {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 40px auto;
  display: block;
  position: relative;

}
.service-card img {
  width: 240px;
  height: 240px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  margin: 10px auto;
}
.service-card h4 {
  width: 100%;
}