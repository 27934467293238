@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Cormorant:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Comfortaa", sans-serif;
}

.container-fluid {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

html {
  font-size: 16px;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
p {
  font-size: clamp(14px, 2vw, 16px);
  line-height: 28px;
  color: #555;
}

@media screen and (max-width: 768px) {
  p {
    line-height: 26px;
  }
}
h1 {
  font-weight: 200;
  font-size: clamp(52px, 6vw, 80px);
  font-family: "Comfortaa", sans-serif;
}

h2 {
  font-weight: 200;
  font-size: clamp(40px, 4.5vw, 76px);
  font-family: "Cormorant", serif;
}

h3 {
  font-weight: 200;
  font-size: clamp(22px, 4vw, 32px);
  font-family: "Comfortaa", sans-serif;
}

h4 {
  font-weight: 200;
  font-size: clamp(22px, 3vw, 28px);
  font-family: "Cormorant", serif;
}