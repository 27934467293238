@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Cormorant:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");

/* HEADER */
header .overlay {
  height: 40vh;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  z-index: 10;
}
.services-header {
  margin: 0;
  padding: 0;
  background-image: url(../images/banner.jpg);
  background-size: cover;
  background-position: center;
  height: 40vh;
  position: absolute;
  top: 0;
  width: 100%;
}
.services-header .main-title {
  font-weight: 300;
  margin-bottom: 10px;
  color: #f0f0f0;
  max-width: 1100px;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  display: block;
  text-align: right;

  position: absolute; 
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: 90vw;
  bottom: 0;
  z-index: 20;
}

.services {
    max-width: 1100px;
    display: block;
    margin-top: calc(40vh + 75px);
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 105px;
    padding: 15px;
  }
  .services h2 {
    width: 100%;
    text-align: right;
    color: #11143D;
    margin-bottom: 40px;
  }
  .services h3 {
    width: 100%;
    text-align: right;
    color: #866502;
    margin-bottom: 75px;
  }

  .services .col {
    overflow: hidden;
    width: fit-content;
    max-width: 280px;
    margin: 10px auto;

  }
  .Link {
    color: black;
    text-decoration: none;
    overflow: hidden;
  }