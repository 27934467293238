.gold-button {
  padding: 16px 22px;
  background-color: #866502;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: capitalize;
  color: white;
  font-size: 0.8rem;
  font-weight: 300;
  letter-spacing: 0.9px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 2px 8px;
}

.gold-button:hover {
  background-color: #503f0a;
}