@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Cormorant:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");


/* HEADER */
header .about-overlay {
  height: 40vh;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  z-index: 10;
}

.about-header {
  margin: 0;
  padding: 0;
  background-image: url(../images/ct5.jpeg);
  background-size: cover;
  background-position: center;
  height: 40vh;
  position: absolute;
  top: 0;
  width: 100%;
}
.about-header .main-title {
  font-weight: 300;
  margin-bottom: 10px;
  color: #f0f0f0;
  max-width: 1100px;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  display: block;

  position: absolute; 
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: 90vw;
  bottom: 0;
  z-index: 20;
}

.about {
    max-width: 1100px;
    display: block;
    margin-top: calc(40vh + 10px);
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 105px;
    padding: 15px;

    color: #f0f0f0;
  }
  .about-main {
    background-color: #1a1a1a;
    margin-top: 0;
    margin-bottom: 0;
    border: 1px solid #1a1a1a;
  }
  .about * {
    color: #f0f0f0;
  }
  @media screen and (max-width: 992px) {
    .about {
      margin-bottom: 0;
      margin-top: calc(40vh - 40px);
    }
  }
  .about .row {
    margin: 105px 0;
  }
  @media screen and (max-width: 992px) {
    .about .row {
      margin: 40px 0;
    }
  }
  .about .col {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about img {
    height: 480px;
    width: 360px;
    object-fit: cover;
    object-position: center;
    margin: 0 auto;
  }
  @media screen and (max-width: 992px) {
    .about img {
      height: 300px;
    }
  }
  @media screen and (max-width: 768px) {
    .about-row-1 {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  p {
    font-size: clamp(14px, 2vw, 15px) !important;
  }

  .GoldButton {

  }
  