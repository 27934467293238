/* HEADER */

/* HEADER */
header .contact-overlay {
    height: 40vh;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    z-index: 10;
  }
  
  .contact-header {
    margin: 0;
    padding: 0;
    background-image: url(../images/london.jpg);
    background-size: cover;
    background-position: center;
    height: 40vh;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .contact-header .main-title {
    font-weight: 300;
    margin-bottom: 30px;
    color: #f0f0f0;
    margin-top: 25vh;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    position: relative;
    z-index: 20;
  }


  main {
    margin-top: calc(40vh + 105px);
}
.contact-text {
    max-width: 1100px;
    display: block;
    margin: 75px auto;
    width: 90vw;
}
.contact-text p {
  margin-bottom: 75px;
}
.gold-small {
  color: #CAA024;
  margin-bottom: 40px;
}
/*
header .contact-overlay {
    height: 40vh;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    z-index: 10;
  }
  
  .contact-header {
    margin: 0;
    padding: 0;
    background-image: url(../images/london.jpg);
    background-size: cover;
    background-position: center;
    height: 40vh;
    position: absolute;
    top: 0;
  }
 

  .contact-header .main-title {
    font-weight: 300;
    color: #f0f0f0;
    position: relative;
    z-index: 20;
    margin-top: 25vh;
    border: 1px solid white;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }



main {
    margin-top: calc(40vh + 105px);
}
section {
    max-width: 1100px;
    display: block;
    margin: 75px auto;
    width: 90vw;
}
*/