.service-page {
    height: calc(40vh - 75px);
    margin-bottom: 0;
    position: relative;
}

.service-page h2 {
    font-weight: 300;
    color: #f0f0f0;
    /*
    margin-bottom: 30px;
    margin-top: 25vh;
    */
    position: absolute;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    z-index: 20;
    text-align: center;
    width: 100%;
}
.service-page .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    z-index: -5;
}
.service-page-img {
    height: 40vh;
    width: 100vw;
    object-fit: cover;
    object-position: center;
    position: fixed;
    top: 0;
    z-index: -10;
}

.service-main {
    background-color: #e3e3e3;
    margin-top: 0;
    margin-bottom: 0;
    border: 1px solid #e3e3e3;
    padding-top: 40px;
}
.service-main-wrapper {
    max-width: 1100px;
    display: block;
    margin: 40px auto 105px auto;
    padding: 10px;
    overflow: hidden;
}
/*header styles */

.gold {
    color: #866502;
    font-weight: 900;
    margin-top: 75px;
    margin-bottom: 75px;
}

.slide-photos {
    max-width: 660px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 10px;
    padding-left: 10px;
}
.slide-photos img {
    height: 100%;
}

.gallery-caption {
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
    text-align: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.gallery {
    max-width: 1100px;
    width: 100vw;
    position: relative;
    display: block;
    margin: 0 auto;
    padding: 0 5px;
    /*width: fit-content;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;*/
    
}
.gallery .col {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    align-items: center;
}
.gallery img {
    width: 380px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.gold, .gold * {
    color: #866502;
    font-size: clamp(18px, 3.5vw, 26px) !important;
    font-weight: 500;
}

