/* FOOTER */
footer {
  width: 100%;
  font-size: 14px !important;
  margin: 0;
  padding-top: 75px;
  margin-top: 0;
  background-color: #11143D;
  color: white;
}
footer a {
  color: white;
  display: block;
}
footer img.footer-logo {
  height: 320px;
  margin: 0;
  padding: 0;
}
footer .row {
  padding: 0;
  margin: 0;
}
footer .row .col {
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-grid-column-align: center;
      justify-self: center;
  justify-items: center;
  text-align: center;
}
footer .row .col a {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: white;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
footer .row .col a:hover, footer .row .col a:active {
  color: rgb(232, 232, 232);
}
footer .social {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding-bottom: 35px;
}
footer .social img {
  height: 26px;
  margin: 0 7px;
}

footer .nav-item, footer .nav-item * {
  text-decoration: none;
  padding: 3px;
  margin: 0;
}